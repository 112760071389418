.portfolio__locations {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(268px, 1fr));
    gap: 1.2rem;
}

.portfolio__location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio__location a {
    transition: all 0.3s;
}

.portfolio__location a:hover {
    transform: translateY(-0.2rem);
}

.portfolio__header {
    text-align: center;
    margin: 2rem 0rem;
}

.portfolio__image {
    width: 100%;
    height: 100%;
    aspect-ratio: 5/3;
    object-fit: cover;
    border-radius: 0.5rem;
}

@media screen and (max-width: 768px) {
    .portfolio__locations {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}