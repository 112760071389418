video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

#home {
    padding-bottom: 1rem;
}

@media screen and (max-width: 1100px) {
    video {
        padding-top: 3rem;
    }

    .newsletter__wrap {
        padding-top: 3rem;
    }

    .newsletter p {
        font-size: .8rem;
    }
}