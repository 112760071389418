.newsletter__wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
}

.newsletter p {
    max-width: 18rem;
}

@media screen and (max-width: 1100px) {
    .newsletter__wrap {
        padding-top: 3rem;
    }

    .newsletter p {
        font-size: .8rem;
    }
}