

.member__content {
    text-align: center;
    max-width: 30rem;
    margin: 2rem 0;
    display: flex;
}

.member__content p {
    margin-bottom: 1rem;
}

.member__content .press__button__text {
    text-align: left;
    margin-top: 5rem;
}

.member__content .press__button__text .cta__button {
    min-width: 500px !important;
}

.member__info {
    min-width: 30rem;
}

.qr {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    display: block;
}

@media screen and (max-width: 450px) {
    .press__button__text img {
        height: 50px;
        width: 50px;
    }
}

@media screen and (max-width: 768px) {
    .member__content {
        flex-direction: column;
        gap: 2rem;
        flex-direction: column-reverse;
        /* align-items: center; */
        margin: 0 auto;
    }

    .member__content .press__button__text .cta__button {
        min-width: 300px !important;
    }

    .member__info {
        min-width: 100%;
    }

    .qr {
        margin-top: 0rem;
    }

    .press__button__text {
        margin-top: 2rem !important;
    }
}