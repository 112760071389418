
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.2rem 0;
    z-index: 4;
}

header.scrolled {
    background: var(--bg-dark);
    box-shadow: 0 5px 20px rgba(0, 0, 0, .5);
    transition: background-color 0.3s, box-shadow 0.3s;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.menu-icon {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
    z-index: 4;
}

.menu-icon div {
    width: 30px;
    height: 3px;
    background-color: var(--bg-dark);
    transition: transform 0.3s ease, opacity 0.2s ease;
}

.scrolled .menu-icon div {
    background-color: var(--text-white);
}

.menu-icon.open div:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.menu-icon.open div:nth-child(2) {
    opacity: 0;
}

.menu-icon.open div:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

nav ul {
    display: flex;
    gap: 2rem;
    list-style: none;
}

nav ul li a {
    color: var(--text-dark);
    text-decoration: none;
    font-weight: 400;
    font-size: .9rem;
    text-transform: uppercase;
}

nav ul li a:hover {
    text-decoration: underline;
    color: var(--text-black);
}

.scrolled nav ul li a {
    color: var(--text-gray);
}

.nav__link {
    color: var(--text-dark);
    text-decoration: none;
    font-weight: 400;
    font-size: .9rem;
    text-transform: uppercase;
}

.nav__link:hover {
    text-decoration: underline;
    color: var(--text-black);
}

.scrolled .nav__link {
    color: var(--text-gray);
}

.nav-links {
    margin: 0;
    padding: 0;
    display: flex;
}

.logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

.logo__link {
    display: flex;
    align-items: center;
    gap: .5rem;
    transition: all .3s;
    color: var(--text-white);
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: 'the-seasons', sans-serif !important;
}

.logo__link:hover {
    transform: scale(1.02);
}

.left__nav {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    width: 25rem;
    transition: all .5s;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--bg-dark);
    z-index: 6;
    gap: 3rem;
}

.left__nav__menu {
    transform: translateX(-100%);
    opacity: 0;
}

.left__nav__menu__open {
    transform: translateX(0%);
}

.left__nav__menu__open .menu-icon div {
    background-color: var(--text-white);
}

.left__nav__menu__open h4 {
    color: var(--text-white);
    font-size: .9rem;
    margin-bottom: .5rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.left__nav__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.left__nav__list a {
    font-weight: 300;
    transition: all .1s;
    padding: 1rem 0rem;
    color: var(--text-gray);
}

.left__nav__list a:hover {
    color: var(--text-white );
    font-weight: 400;
    text-decoration: none;
    padding-left: .2rem;
}

.left__main__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.right__nav .cta__button:hover {
    color: var(--text-white) !important;
}

#currentLanguage {
    display: flex;
    align-items: center;
    gap: .3rem;
    justify-content: center;
    padding-top: 0 !important;
    color: var(--text-black);
}

.scrolled #currentLanguage {
    color: var(--text-white);
}

#currentLanguage:hover {
    text-decoration: none !important;
}

.nav-scrolled #currentLanguage {
    color: var(--bg-dark-gray);
}

.dropdown {
    position: relative;
    cursor: pointer;
    display: flex;
}

.dropdown-content {
    position: absolute;
    background-color: var(--bg-beige);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 2.5rem;
    left: -1rem;
}

.dropdown-content a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown-content a span {
    margin-top: .1rem;
}

.flag__icon {
    border-radius: 0rem;
}

.scrolled .dropdown-content {
    background-color: var(--bg-dark);
    color: var(--text-black);
}

.scrolled .dropdown-content a {
    color: var(--text-white);
}

.dropdown-content a {
    color: var(--text-black);
    padding: 12px 16px;
    text-decoration: none !important;
    display: flex;
    gap: .5rem;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.scrolled .dropdown-content a:hover {
    background-color: var(--bg-dark-gray);
}

@media screen and (max-width: 600px) {
    .left__nav__menu__open {
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .left__main__nav a {
        display: none;
    }

    .logo__image {
        height: 30px;
        object-fit: contain;
    }

    .right__nav li:nth-child(1) {
        display: block !important;
    }

    .dropdown-content {
        right: 0;
        left: auto;
    }

    .right__nav li:nth-child(2),
    .right__nav li:nth-child(3),
    .right__nav li:nth-child(4),
    .right__nav li:nth-child(5) {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    .right__nav li:nth-child(1),
    .right__nav li:nth-child(2),
    .right__nav li:nth-child(3) {
        display: none;
    }
}