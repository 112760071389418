#calendar {
    padding-bottom: 10rem;
}

#calendar h2 {
    margin-top: var(--top-margin);
    margin-bottom: 1rem;
}

.calendar__events {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(268px, 1fr));
    gap: 1.2rem;
}

.calendar__event {
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.calendar__event-info {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.calendar__event-info p,
.calendar__event-info a {
    font-size: .8rem;
}

.calendar__event-image img {
    width: 100%;
    object-fit: cover;
}

.calendar__event-image {
	position: relative;
}

.calendar__date {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
}

.calendar__day {
	font-size: 24px; 
	font-weight: bold;
}

.calendar__month {
	font-size: 16px;
}

.event__description {
    margin-bottom: .5rem;
}

.cta__button {
    background: var(--bg-dark) !important;
    padding: .5rem 1rem;
    color: var(--text-white);
    text-decoration: none;
    border-radius: var(--border-radius);
    margin-top: auto;
    transition: all .2s ease;
    align-self: flex-start;
}

.cta__button:hover {
    transform: translateY(-.2rem);
}

.recents {
    opacity: .7;
}

@media screen and (max-width: 768px) {
    .calendar__events {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    .calendar__event-image img {
        width: 100%;
    }
}
