/* footer */
footer {
    padding-bottom: 3rem;
    padding-top: 5rem;
    background: var(--bg-dark);
}

footer a:hover {
  color: var(--text-white) !important;
}

footer h4, footer h2 {
    color: var(--text-white);
}
  
footer hr {
  border: none;
  border-top: 0.01rem solid var(--text-gray);
  opacity: 0.7;
}

.lower-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  opacity: .7;
  color: var(--text-gray);
}

.lower-footer p,
.lower-footer ul,
.lower-footer a {
  font-size: 0.8rem;
}

.lower-footer ul {
  display: flex;
  list-style: none;
  gap: 1.3rem;
}

.lower-footer a {
  color: var(--text-white);
}

.upper-footer {
  display: flex;
  justify-content: space-between;
}

.footer-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

footer .contact {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--text-gray);
  font-size: .9rem;
}

.contact span,
.contact a {
  font-size: .9rem;
}

footer .contact a {
  color: var(--text-gray);
}

.footer-socials {
  display: flex;
  gap: 1rem !important;
  margin-bottom: 4rem;
}

.social__icon {
  transition: all 0.15s;
}

.social__icon:hover {
  color: var(--text-white) !important;
  transform: translateY(-.1rem);
}

.footer-right {
  display: flex;
  gap: 4rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-links a {
  color: #888;
  text-decoration: none;
  font-size: .9rem;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-links strong {
  font-size: 1.1rem;
}

@media screen and (max-width: 768px) {
  footer {
    padding-top: 3rem;
  }

  .footer-left {
    gap: 1rem;
  }

  .footer-right {
    flex-direction: column;
    gap: 2rem;
  }

  .footer-links {
    gap: 0.5rem;
  }

  .footer-links strong {
    font-size: 1rem;
  }

  .footer-links a {
    font-size: .8rem;
  }

  .footer-links a:hover {
    text-decoration: none;
  }

  .footer-socials {
    margin-bottom: 2rem;
    align-items: center;
    justify-content: center;
  }

  .upper-footer {
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  
}