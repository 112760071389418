/* css reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://use.typekit.net/wra1hqb.css");

:root {
	--border-radius: .5rem;
	--bg-dark: #131923;
	--text-white: #f1f1f1;
	--text-gray: #bfbfbf;
	--text-black: #131923;
	--text-dark: #666;
  
	--bg-beige: #f9f7ef;
  
	--top-margin: 4rem;
	--negative-top-margin: -4rem;

	--color-blue: #006bf5;
	--color-orange: #ff7c48;
  }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background: #FAF8F3;
}

.darken::after {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "poppins", sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "the-seasons", serif !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h1 {
	font-size: 2rem;
}

p, span, a {
	font-size: .9rem;
	font-weight: 300;
	letter-spacing: 1px;
}

button {
	font-size: .9rem;
	font-weight: 400;
	letter-spacing: 1px;
	padding: .5rem 1rem;
	transition: all .15s;
}

button:hover {
	cursor: pointer;
	transform: translateY(-.1rem);
}

.btn__primary {
	background-color: var(--color-blue) !important;
	color: var(--text-white);
	padding: .5rem 1rem;
	border: none;
	border-radius: var(--border-radius);
}

input:focus, textarea:focus {
	outline: none;
}

section {
	padding: var(--top-margin) 0rem;
}

.container {
	width: min(calc(100% - 2rem), 1200px);
	margin: 0 auto;
	padding: 0 1rem;
}

.wide__container {
	width: min(calc(100% - 2rem), 2000px);
	margin: 0 auto;
	padding: 0 1rem;
}

img {
	border-radius: var(--border-radius);
}

.image__text {
	display: flex;
	padding: 2rem 0;
	gap: 4rem;
	align-items: center;
}

.text__section {
	text-align: center;
	padding: 2rem 0;
	width: 100%;
}

.text__section p {
	max-width: 45rem;
	margin: 0 auto;
}

@media screen and (max-width: 768px) {
	.container {
		width: 100%;
	}

	.wide__container {
		width: 100%;
	}

	.image__text {
		flex-direction: column;
		gap: 2rem;
	}

	.text__section {
		text-align: left;
		padding: 0rem 0;
	}

	.image__text img {
		width: 100%;
		object-fit: cover;
	}

	h1 {
		font-size: 1.5rem;
	}
}

.landing__image {
	margin-top: var(--negative-top-margin);
}

/* ANIMATIONS */
.fly-left {
	-webkit-animation: flyInFromLeft .5s ease-in-out;
	animation: flyInFromLeft .5s ease-in-out;
}
  
.fly-right {
	-webkit-animation: flyInFromRight .5s ease-in-out;
	animation: flyInFromRight .5s ease-in-out;
}
  
.fly-bottom {
	-webkit-animation: flyInFromBottom .5s ease-in-out;
	animation: flyInFromBottom .5s ease-in-out;
}
  
.fade-in {
	-webkit-animation: fadeIn .5s ease-in-out;
	animation: fadeIn .5s ease-in-out;
}

/* Start animations with forwards */
.start-animation.fly-left-late {
	-webkit-animation: flyInFromLeft .5s ease-in-out forwards;
	animation: flyInFromLeft .5s ease-in-out forwards;
}
  
.start-animation.fly-right-late {
	-webkit-animation: flyInFromRight .5s ease-in-out forwards;
	animation: flyInFromRight .5s ease-in-out forwards;
}
  
.start-animation.fly-bottom-late {
	-webkit-animation: flyInFromBottom .5s ease-in-out forwards;
	animation: flyInFromBottom .5s ease-in-out forwards;
}
  
.start-animation.fade-in-late {
	-webkit-animation: fadeIn .5s ease-in-out forwards;
	animation: fadeIn .5s ease-in-out forwards;
}
	
/* fadeIn */
@-webkit-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
}
@-moz-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
}
  @-o-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  @keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  
  /* flyInFromRight */
@-webkit-keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
@-moz-keyframes flyInFromRight {
  from { transform: translateX(20%); }
  to { transform: translateX(0); opacity: 1; }
}
  @-o-keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  
  /* flyInFromLeft */
  @-webkit-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-moz-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-o-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  
  /* flyInFromBottom */
  @-webkit-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @-moz-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @-o-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  
  /* moveUpDown */
  @-webkit-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @-moz-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @-o-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }