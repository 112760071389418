#not-found h1 {
    text-align: center;
    margin-top: 10rem;
}

#not-found p {
    text-align: center;
    margin-top: 1rem;
}

#not-found .button__wrap {
    margin-top: 2rem;
    margin-bottom: 10rem;
    display: flex;
    justify-content: center;

}