#contact {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
}

.contact__image-container {
    width: 100%;
    height: 300px; 
    position: relative;
    overflow: hidden; 
}

.contact__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
}

.contact__content {
    padding: 1rem;
}

.contact__content form {
    margin-top: 2rem;
}

.form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

input[type="text"],
input[type="email"],
textarea {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: .5rem 1rem;
}

label {
    font-family: 'the-seasons', serif !important;
    margin-bottom: .5rem;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.form__result {
    margin-top: 1rem;
    font-size: .9rem;
}

#contact button {
    margin-top: 1rem;
}

@media (min-width: 768px) {
    #contact {
        flex-direction: row;
    }

    .contact__image-container {
        width: 50%;
        height: 100vh;
        position: sticky;
        top: 4rem;
    }

    .contact__content {
        padding: 3rem;
        flex: 1;
    }

    .form__title {
        margin-top: 2rem;
    }

    textarea {
        height: 150px;
    }

    button[type="submit"] {
        width: auto;
        padding: 0.5rem 1rem;
    }
}
